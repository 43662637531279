@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

body {
  background: linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgb(1, 7, 61) 100%
  );
  padding: 20px;
}
.todo-app {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: auto;
  max-width: 520px;
  min-height: 600px;
  background: #161a2b;
  text-align: center;
  margin: 128px auto;
  border-radius: 10px;
  padding-bottom: 32px;
  
}

h1 {
  margin: 32px 0;
  color: #fff;
  font-size: 24px;
}

.complete {
  text-decoration: line-through;
  opacity: 0.4;
}

.todo-form {
  margin-bottom: 32px;
}

.todo-input {
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  border: 2px solid #ff0000;
  outline: none;
  width: 320px;
  background: transparent;
  color: #fff;
  border-radius: 15px;
  margin-bottom: 10px;
}

.todo-input::placeholder {
  color: #e2e2e2;
}

.todo-button {
  padding: 16px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  margin-left: 10px;
  background: linear-gradient(
    90deg,
    rgb(8, 10, 94) 0%,
    rgb(46, 133, 33) 100%
  );
  color: #fff;
  text-transform: capitalize;
}

.todo-input.edit {
  border: 2px solid #149fff;
}

.todo-button.edit {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
  padding: 16px 22px;
}

.todo-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.Jassy{
  margin: 40px;
  padding: 20px;
  font-size: large;
  color: #fff;
  text-decoration: none;
  text-align: right;
  font-family: 'Nunito', sans-serif;
  opacity: 30%;

}

.todo-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  color: #fff;
  background: linear-gradient(
    90deg,
    rgb(94, 181, 252) 0%,
    rgba(255, 84, 17, 1) 100%
  );
  

  padding: 16px;
  border-radius: 5px;
  width: 90%;
}

.todo-row:nth-child(4n + 1) {
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgb(157, 102, 192) 100%
  );
  
}

.todo-row:nth-child(4n + 2) {
  background: linear-gradient(
    90deg,
    rgba(255, 12, 241, 1) 0%,
    rgba(250, 0, 135, 1) 100%
  );
  
}

.todo-row:nth-child(4n + 3) {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
  
}

.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.delete-icon {
  margin-right: 5px;
  color: #fff;
}

.edit-icon {
  color: #fff;
}

